$(function () {

    function initMap() {

        var location = new google.maps.LatLng(36.831246, -4.318750);

        var mapCanvas = document.getElementById('map-castillo');
        var mapOptions = {
            center: location,
            zoom: 10,
            panControl: false,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(mapCanvas, mapOptions);

        var markerImage = '../img/marker.png';

        var marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: markerImage
        });

        var contentString = '<div class="info-window z3">' +
                '<h3 class="fs-18 base m0">El Castillo</h3>' +
                '<div class="info-content mt1">' +
                '<p>Es importante seguir nuestras indicaciones para acceder por la ruta mas sencilla, algunos navegadores recomiendan una ruta mas larga y por camino menos acondicionados</p>' +
                '</div>' +
                '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString,
            maxWidth: 280
        });

        infowindow.open(map, marker);
    }

    google.maps.event.addDomListener(window, 'load', initMap);
});
$(function () {

    function initMap() {

        var location = new google.maps.LatLng(36.831246, -4.318750);

        var mapCanvas = document.getElementById('map');
        var mapOptions = {
            center: location,
            zoom: 10,
            panControl: false,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(mapCanvas, mapOptions);

        var markerImage = '../img/marker.png';

        var marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: markerImage
        });

        var contentString = '<div class="info-window z3">' +
                '<h3 class="fs-18 base m0">Lagar Martinez</h3>' +
                '<div class="info-content mt1">' +
                '<p>Es importante seguir nuestras indicaciones para acceder por la ruta mas sencilla, algunos navegadores recomiendan una ruta mas larga y por camino menos acondicionados</p>' +
                '</div>' +
                '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString,
            maxWidth: 280
        });

        
            infowindow.open(map, marker);
      

    }

    google.maps.event.addDomListener(window, 'load', initMap);
});
$(document).ready(function () {
	"use strict";
	init_img_responsive();
	init_img_bg_toggle();
	init_aos();
	init_owl();
	init_owl_reviews();
	init_equal();
	init_header_afix();
	init_input_range();
	init_backtotop();
	int_slider_inter();
	init_cookies_close();
	init_toggle_panel();
	init_toggle_collapse();
	init_submit_booking_form();
	init_rating();
	init_syncOwl();
	init_lazyload();
	init_submit_reviews_form();
	init_carousel();
	init_smoothScroll();
	init_sidebar_affix();
	init_owl_landscape();
});

$(window).resize(function () {
	init_equal();
	init_toggle_panel();
});

function init_img_responsive() {

	var src = $('.js-bg_full').children('img');

	src.each(function () {
		var $source = $(this).attr("src");
		$(this).parent('.js-bg_full').css({
			'backgroundImage': 'url(' + $source + ')'
		});
		$(this).css('display', 'none');
	});
}

function init_img_bg_toggle() {

	$('a[data-toggle="tab"]').on('shown.bs.tab', function () {
		$(this).parents('.js-bg').find('.js-toggle-bg').toggleClass('hide');
	});
}

function init_owl() {
	var owl = $('.owl-casas');
	owl.owlCarousel({
		center: false,
		loop: true,
		nav: false,
		dots: true,
		margin: 16,
		responsive: {
			0: {
				items: 1.1
			},
			480: {
				items: 2.2
			},
			600: {
				items: 2.2
			},
			960: {
				items: 3
			},
			1140: {
				items: 4
			},
		}
	});
}

function init_owl_reviews() {
	var owl = $('.owl-reviews');
	owl.owlCarousel({
		center: false,
		loop: false,
		nav: false,
		dots: true,
		margin: 16,
		responsive: {
			0: {
				items: 1.3
			},
			600: {
				items: 1.3
			},
			960: {
				items: 2
			},
		}
	});
}

function init_equal() {
	equalheight = function (container) {
		var currentTallest = 0,
			currentRowStart = 0,
			rowDivs = new Array(),
			$el,
			topPosition = 0;
		$(container).each(function () {

			$el = $(this);
			$($el).height('auto');
			topPostion = $el.position().top;

			if (currentRowStart != topPostion) {
				for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					rowDivs[currentDiv].height(currentTallest);
				}
				rowDivs.length = 0; // empty the array
				currentRowStart = topPostion;
				currentTallest = $el.height();
				rowDivs.push($el);
			} else {
				rowDivs.push($el);
				currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			}
			for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
				rowDivs[currentDiv].height(currentTallest);
			}

		});

	};

	equalheight('.equal');
}

function init_input_range() {

	$(".js-flatpickr").flatpickr({
		"locale": "es",
		dateFormat: "d-m-Y",
		allowInput: true,
		mode: "range",
		"plugins": [new rangePlugin({
			input: ".js-flatpickr_input"
		})]
	});

	$(".js-flatpickr_home").flatpickr({
		"locale": "es",
		dateFormat: "d-m-Y",
		allowInput: true,
		mode: "range",
		"plugins": [new rangePlugin({
			input: ".js-flatpickr_input_home"
		})]
	});

}

function init_submit_booking_form() {

	$('#submit_booking_form').on('submit', function (e) {
		e.preventDefault();
		$('.js-submit_booking_form').hide();
		$('.js-submit_booking_form_success').show();
		return $("body,html").animate({
			scrollTop: 0
		}, 400), !1;
	});
}

function init_backtotop() {
	$(window).scroll(function () {
			$(this).scrollTop() > 50 ? $(".js-backtotop").fadeIn() : $(".js-backtotop").fadeOut();
		}),
		$(".js-backtotop, .js-totop").click(function () {
			return $("body,html").animate({
				scrollTop: 0
			}, 800), !1;
		});
}

function int_slider_inter() {
	if ($('.js-slider_inter').length) {
		$('.js-slider_inter').owlCarousel({
			loop: true,
			nav: false,
			dots: true,
			items: 4,
			autoplay: true,
			smartSpeed: 700,
			autoplayTimeout: 4000,
			responsive: {
				0: {
					items: 1
				},
				767: {
					items: 2
				},
				1024: {
					items: 3
				},
				1441: {
					items: 4
				}
			}
		});
	}

}

function init_cookies_close() {
	$('.js-cookies_close').on('click', function () {
		$(this).parent('.js-cookies').remove();
	});
}

function init_toggle_panel() {
	var w = $(window).width();

	if (w <= 991) {
		$('.js-collapse').removeClass('in');
		$('.js-collapse_btn').addClass('collapsed');
	} else {
		$('.js-collapse').addClass('in').css('height', 'auto');
		$('.js-collapse_btn').removeClass('collapsed');
	}
}

function init_toggle_collapse() {
	$('.js-collapse_edit').click(function () {
		var $this = $(this);
		$this.toggleClass('collapse__edit');
		if ($this.hasClass('collapse__edit')) {
			$this.text('Modificar datos búsqueda');
		} else {
			$this.text('Cancelar');
		}
	});
}

function init_aos() {
	AOS.init({
		disable: "mobile"
	});
}

function init_lazyload() {
	(function () {
		new LazyLoad({
			elements_selector: ".lazy",
			load_delay: 300
		});
	}());
}

function init_rating() {
	$('.js-rating input').change(function () {
		var $radio = $(this);
		$radio.closest('label').addClass('selected');
	});
}


function init_submit_reviews_form() {

	$('#submit_reviews_form').on('submit', function (e) {
		e.preventDefault();
		$('.js-submit_reviews_form').hide();
		$('.js-submit_reviews_form_success').show();
		return $("body,html").animate({
			scrollTop: 0
		}, 400), !1;
	});
}

function init_carousel() {
	$('.carousel').carousel({
		interval: 6000,
		pause: "false"
	});
}

function init_smoothScroll() {
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top
					}, 1000, function () {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(":focus")) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						};
					});
				}
			}
		});
}

function init_header_afix() {
	var headerHeigth = $('header').outerHeight() - 70;
	$('.header').affix({
		offset: {
			top: headerHeigth,
			bottom: function () {
				return (this.bottom = $('.footer').outerHeight(true));
			}
		}
	});
}

function init_sidebar_affix() {

	(function($) {

		function mediaSize() {
			if (window.matchMedia('(max-width: 991px)').matches) {
			
				$('.js-sidebar-movil').affix({
					offset: {
						top: $('.js-sidebar-movil').offset().top,
						bottom: function () {
							return (this.bottom = $('.footer').outerHeight(true));
						}
					}
				});
			}
			else {
				
				$('.js-sidebar').affix({
					offset: {
						top: $('.js-sidebar').offset().top,
						bottom: function () {
							return (this.bottom = $('.footer').outerHeight(true));
						}
					}
				});
			}
		};
	
	   mediaSize();
	
		window.addEventListener('resize', mediaSize, false);
	
	})(jQuery);
}

function init_owl_landscape() {
	var owl = $('.js-owl_scape');
	owl.owlCarousel({
		nav: false,
		dots: true,
		margin: 10,
		responsiveClass: true,
		lazyLoad: true,
		responsive: {
			0: {
				items: 2.5
			},
			600: {
				items: 3
			}
		}
	});
}

function init_syncOwl() {
  var sync1 = $("#sync1");
  var sync2 = $("#sync2");

  var thumbnailItemClass = '.owl-item';

  var slides = sync1.owlCarousel({
    startPosition: 0,
    items: 1,
    loop: false,
    margin: 10,
    nav: true,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    dots: false
  }).on('changed.owl.carousel', syncPosition);

  function syncPosition(el) {
    $owl_slider = $(this).data('owl.carousel');
    var loop = $owl_slider.options.loop;

    if (loop) {
      var count = el.item.count - 1;
      var current = Math.round(el.item.index - (el.item.count / 2) - .5);
      if (current < 0) {
        current = count;
      }
      if (current > count) {
        current = 0;
      }
    } else {
      var current = el.item.index;
    }

    var owl_thumbnail = sync2.data('owl.carousel');
    var itemClass = "." + owl_thumbnail.options.itemClass;


    var thumbnailCurrentItem = sync2
      .find(itemClass)
      .removeClass("synced")
      .eq(current);

    thumbnailCurrentItem.addClass('synced');

    if (!thumbnailCurrentItem.hasClass('active')) {
      var duration = 300;
      sync2.trigger('to.owl.carousel', [current, duration, true]);
    }
  }
  var thumbs = sync2.owlCarousel({
      startPosition: 0,
      items: 4,
      loop: false,
      margin: 10,
      autoplay: false,
      nav: false,
      dots: false,
      onInitialized: function (e) {
        var thumbnailCurrentItem = $(e.target).find(thumbnailItemClass).eq(this._current);
        thumbnailCurrentItem.addClass('synced');
      },
    })
    .on('click', thumbnailItemClass, function (e) {
      e.preventDefault();
      var duration = 300;
      var itemIndex = $(e.target).parents(thumbnailItemClass).index();
      sync1.trigger('to.owl.carousel', [itemIndex, duration, true]);
    }).on("changed.owl.carousel", function (el) {
      var number = el.item.index;
      $owl_slider = sync1.data('owl.carousel');
      $owl_slider.to(number, 100, true);
    });

}